/*
* TODO: Error 404 ITCSS stack does not necessery have to have all these imports.
* Most important thing is to omit all icon imports.
* Otherwise asiointi-application will have duplicate icon declarations which will cause icons to disappear.
 */
define('rootPath', './', true)
define('fontPath','../../fonts/', true)
appRootPath = rootPath + 'app/'


/*
* ITCSS STACK:
*
* Settings
*/
@require 'itcss/settings/settings.font-ubuntu'
@require 'itcss/settings/settings.images.styl'
@require 'itcss/settings/settings.colors'

/*
* Tools
*/
@require 'itcss/tools/tools.mixins'

/*
* Generic
*/
@require 'itcss/generic/generic.box-sizing'


body.error-page
  background #f3f3f3

  height 100%
  width 100%
  margin 0
  padding 6.5rem 2rem  1rem 2rem
  color #000
  font-family Ubuntu
  font-size 1.6rem

  a, a:link, a:hover, a:focus, a:visited
    color $color-blue-base
    text-decoration none

  p
    margin 0.5rem 0


  h1
    font-weight normal
    font-size 1rem
    margin-bottom 2rem
    font-size 1.8rem

  .error-page__logo-bar
    height 65px
    background #333
    position fixed
    top 0
    width 100%
    left 0
    padding 0.5rem 0 0 1rem
    box-sizing border-box

  .error-page__logo
    width 96px
    position static
    top auto
    left auto
    background url($img-logo-fi)
    height 45px
    display block
    background-size 100%

  .error-page__sign-404
    height 200px
    margin 0 0 0 5rem
    width 200px


  .error-page__content
    width 320px
    margin 0 auto

  .error-page--bold
    font-weight bold

  .error-page--links
    margin-top 2rem

  .error-page__sign-404--st0
    fill #bfbfbf

  .error-page__sign-404--st1
    fill #FFFFFF

  .error-generic
    font-style normal
    font-variant normal
    font-weight normal
    font-stretch normal
    font-size 60px
    line-height 125%
    font-family sans-serif
    -inkscape-font-specification sans-serif, Normal
    text-align start
    letter-spacing 0px
    word-spacing 0px
    writing-mode lr-tb
    text-anchor start
    fill #ffffff
    fill-opacity 1
    stroke none
    stroke-width 1px
    stroke-linecap butt
    stroke-linejoin miter
    stroke-opacity 1

/*
* Large views
*/

@media (min-width 640px)

  body.error-page
    background-color #000
    background-repeat no-repeat
    background-image url($img-bg-summer-hires)
    background-position 100% 100%
    color white
    padding 0
    font-size 1.6rem

    .error-page__bg-overlay
      padding 2rem
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      background-color rgba(0,0,0,0.78)

    h1
      font-size 150%

    .error-page__content
      padding 2rem 0 2rem 4rem
      width 100%
      margin 0

    .error-page__logo-bar
      background-color transparent
      position static

    .error-page__sign-404--st0
      fill #FFFFFF

@media (min-width 1981px)
  body.error-page
    background-size cover

// Some buttons in the codebase expect to inherit their
// colour. Should those get changed, this can be deleted.
button
  color inherit

//*** LEGACY FROM STYLEGUIDE V1
html, body
  width 100%
  height 100%

html
  font-size 62.5%; /* Now 10px = 1rem! */
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)

body
  ubuntu-regular-font()
  background $color-bg-base
  color $color-text-base
  font-size 16px; /* px fallback */
  font-size 1.6rem; /* default font-size for document */
  line-height 1

pre
  margin 0

/* Remnants of the nib/reset. */
h1,
h2,
h3,
h4,
h5,
h6
  margin 0
  font-size 100%
  vertical-align baseline

p
  margin 0
  vertical-align baseline

a
  &,
  &:active
    color $link-color
    outline 0
    text-decoration none

img
  vertical-align middle

// Reset fonts for relevant elements
input,
select,
textarea
  font-family inherit
  font-size inherit
  line-height inherit

/*
 * iOS "no text when typing" bugfix
 */
input {
    -webkit-user-select: auto !important;
    user-select: auto !important;
}
